// Summary And Greeting Section

import emoji from 'react-easy-emoji';

const greeting = {
  username: 'Kathy Tavia',
  title: "Hi, I'm Kathy",
  subTitle: emoji(
    'A passionate and growth minded Full Stack Developer 👩🏾‍💻 having experience utilizing UX / UI design and A/B testing for building web applications with JavaScript, Reactjs, and Ruby on Rails.'
  ),
  resumeLink: 'https://kathytavia.me/',
  displayGreeting: true, // Set false to hide this section, defaults to true
};
// Social Media Links

const socialMediaLinks = {
  github: 'https://github.com/cdrmr18',
  linkedin: 'https://www.linkedin.com/in/kathytavia/',
  gmail: 'kathy.tavia@gmail.com',
  stackoverflow: 'https://stackoverflow.com/users/17656980/cdrmr18',
  instagram: 'https://www.instagram.com/kt_smilez/',
  twitter: 'https://twitter.com/kathy_tavia',
  display: true, // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: 'What I do',
  subTitle:
    'FULL STACK DEVELOPER WORKING FROM HOME WHO WANTS TO CONTINUE TO EXPLORE TECH AND LEARN',
  skills: [
    emoji('⚡ Develop interactive, user friendly full stack web applications'),
    emoji('⚡ Implmenet successful features from A/B tests'),
    emoji('⚡ Creating automations for improved workflows'),
    emoji('⚡ Integration of third party services and APIs'),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: 'html-5',
      fontAwesomeClassname: 'fab fa-html5',
    },
    {
      skillName: 'css3',
      fontAwesomeClassname: 'fab fa-css3-alt',
    },
    {
      skillName: 'sass',
      fontAwesomeClassname: 'fab fa-sass',
    },
    {
      skillName: 'JavaScript',
      fontAwesomeClassname: 'fab fa-js',
    },
    {
      skillName: 'reactjs',
      fontAwesomeClassname: 'fab fa-react',
    },
    {
      skillName: 'ruby',
      fontAwesomeClassname: 'far fa-gem',
    },
    {
      skillName: 'nodejs',
      fontAwesomeClassname: 'fab fa-node',
    },
    {
      skillName: 'npm',
      fontAwesomeClassname: 'fab fa-npm',
    },
    {
      skillName: 'PostgresSQL',
      fontAwesomeClassname: 'fas fa-database',
    },
    {
      skillName: 'AngualryJS',
      fontAwesomeClassname: 'fab fa-angular',
    },
    {
      skillName: 'Google Analytics',
      fontAwesomeClassname: 'fas fa-chart-line',
    },
    {
      skillName: 'Digital Marketing',
      fontAwesomeClassname: 'fas fa-funnel-dollar',
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: 'Le Wagon Coding Bootcamp',
      logo: require('./assets/images/lewagon.png'),
      subHeader: 'Full Stack Web Development',
      duration: 'October 2020 - March 2021',
      // desc: 'Participated in the research of XXX and published 3 papers.',
      // descBullets: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit'],
    },
    {
      schoolName: 'Central Washington University',
      logo: require('./assets/images/cwu.png'),
      subHeader: 'Bachelor of Arts in Psychology',
      duration: 'September 2014 - June 2016',
      // desc:
      //   'Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...',
      // descBullets: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit'],
    },
  ],
};

// Your top 3 proficient stacks/tech experience

// const techStack = {
//   viewSkillBars: true, //Set it to true to show Proficiency Section
//   experience: [
//     {
//       Stack: 'Frontend / Design',
//       progressPercentage: '50%',
//     },
//     {
//       Stack: 'Backend',
//       progressPercentage: '40%',
//     },
//     {
//       Stack: 'Programming',
//       progressPercentage: '30%',
//     },
//   ],
//   displayCodersrank: true, // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
// };

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: 'Growth Engineer',
      company: 'Storefront',
      companylogo: require('./assets/images/sf.jpeg'),
      date: 'May 2020 – Present',
      desc:
        'Take over and manage end to end an array of growth tactics across the entire funnel and implement successful features.',
      descBullets: [
        'Ideate new experiments, product features, customer acquisition campaigns etc. ',
        'Build experiment hypothesis & design it end to end',
        'Stack: Ruby, React, JS, Node.js, AngularJS, HTML/CSS, data analytics, Google Analytics, Segment, Optimize, 3rd party REST APIs',
      ],
    },
    {
      role: 'Full Stack Developer Consultant',
      company: 'MangoTree',
      companylogo: require('./assets/images/mango.jpeg'),
      date: 'July 2021 – September 2021',
      desc:
        'Working as part of a 4-person team to develop an app to production that provides people with gambling addiction in Australia with resources to financial counselors.',
      descBullets: [
        'UX/UI design, prototyping',
        'Stack: Ruby on Rails, JavaScript, HTML/CSS, RESTful App, Figma, Heroku, Rspec',
      ],
    },
    {
      role: 'Full Stack Developer Consultant',
      company: 'Wesley Mission',
      companylogo: require('./assets/images/wesley.jpeg'),
      date: 'March 2021 – May 2021',
      desc:
        'Assisting in the build of an MVP for a web app connecting counsellors with gamblers seeking support with their addiction.',
      descBullets: [
        'Implementing backend features and database',
        'Frontend design and implementation',
        'UX, debugging, testing to ensure the best experience for users',
      ],
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: 'cdrmr18', // Change to your github username to view your profile in Contact Section.
  showGithubProfile: 'true', // Set true or false to show Contact profile using Github, defaults to true
  display: false, // Set false to hide this section, defaults to true
};

// Projects section

const bigProjects = {
  title: 'Projects',
  subtitle: '',
  projects: [
    {
      image: require('./assets/images/cwu.png'),
      projectName: 'Saayahealth',
      projectDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      footerLink: [
        {
          name: 'Visit Website',
          url: 'fa',
        },
        //  you can add extra buttons here.
      ],
    },
    {
      image: require('./assets/images/cwu.png'),
      projectName: 'Nextu',
      projectDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      footerLink: [
        {
          name: 'Visit Website',
          url: '',
        },
      ],
    },
  ],
  display: false,
};

// Achievement Section

const achievementSection = {
  title: emoji('Achievements And Certifications 🏆 '),
  subtitle:
    'Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !',

  achievementsCards: [
    {
      title: 'Google Code-In Finalist',
      subtitle:
        'First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.',
      image: require('./assets/images/cwu.png'),
      footerLink: [
        {
          name: 'Certification',
          url: '',
        },
        {
          name: 'Award Letter',
          url: '',
        },
        {
          name: 'Google Code-in Blog',
          url: '',
        },
      ],
    },
    {
      title: 'Google Assistant Action',
      subtitle:
        'Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.',
      image: require('./assets/images/cwu.png'),
      footerLink: [
        {
          name: 'View Google Assistant Action',
          url: '',
        },
      ],
    },

    {
      title: 'PWA Web App Developer',
      subtitle: 'Completed Certifcation from SMIT for PWA Web App Development',
      image: require('./assets/images/cwu.png'),
      footerLink: [
        { name: 'Certification', url: '' },
        {
          name: 'Final Project',
          url: '',
        },
      ],
    },
  ],
  display: false,
};

// Blogs Section

const blogSection = {
  title: 'Blogs',
  subtitle:
    'With Love for Developing cool stuff, I love to write and teach others what I have learnt.',

  blogs: [
    {
      url: '',
      title: '',
      description: '',
    },
  ],
  display: false,
};

const contactInfo = {
  title: emoji('Contact Me'),
  subtitle:
    'Discuss a project or just want to say hi? Feel free to send me a message.',
  email_address: 'kathy.tavia@gmail.com',
};

// Twitter Section

const twitterDetails = {
  userName: 'kathy_tavia',
  display: true,
};

export {
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  // techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  contactInfo,
  twitterDetails,
};
