import React, { Component } from 'react';
import Header from '../components/header/Header';
import Greeting from './greeting/Greeting';
import Skills from './skills/Skills';
// import StackProgress from './skillProgress/skillProgress';
import WorkExperience from './workExperience/WorkExperience';
import Projects from './projects/Projects';
import StartupProject from './StartupProjects/StartupProject';
import Achievement from './achievement/Achievement';
import Blogs from './blogs/Blogs';
import Footer from '../components/footer/Footer';
import Education from './education/Education';
import Top from './topbutton/Top';
import Twitter from './twitter-embed/twitter';
import { StyleProvider } from '../contexts/StyleContext';
import './Main.css';
import Profile from './profile/Profile';

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDark: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('isDark') === null) {
      const darkPref = window.matchMedia('(prefers-color-scheme: dark)');
      localStorage.setItem('isDark', darkPref.matches);
    }
    this.setState({ isDark: JSON.parse(localStorage.getItem('isDark')) });
  }
  changeTheme = () => {
    this.setState({ isDark: !this.state.isDark }, () => {
      localStorage.setItem('isDark', this.state.isDark);
    });
  };

  render() {
    return (
      <div className={this.state.isDark ? 'dark-mode' : null}>
        <StyleProvider
          value={{ isDark: this.state.isDark, changeTheme: this.changeTheme }}
        >
          <Header />
          <Greeting />
          <Skills />
          {/* <StackProgress /> */}
          <Projects />
          <WorkExperience />
          <Education />
          <StartupProject />
          <Achievement />
          <Blogs />
          <Twitter />
          <Profile />
          <Footer />
          <Top />
        </StyleProvider>
      </div>
    );
  }
}
